<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Growth Submissions</v-card-title>
          <v-card-subtitle>Submissions that we have received from the growth submission form on the main website.</v-card-subtitle>
          <v-card-text>
            <TableCrudComponent endpoint="/api/growth-submission" table-name="Growth Submissions"></TableCrudComponent>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableCrudComponent from "@/components/tables/TableCrudComponent";
export default {
  name: "GrowthSubmissions",
  components: {TableCrudComponent}
}
</script>

<style scoped>

</style>
